
import OfficeMap from '@/components/OfficeMap.vue';

import { prettifyPhone } from '@/api';
import { defineComponent, inject } from 'vue';
import Button from 'primevue/button';

export default defineComponent({
  name: 'Footer',
  components: {
    Button,
    OfficeMap,
  },
  setup() {
    const socials = inject('socials');
    const publicPhone = inject('publicPhone');
    const publicAddress = inject('publicAddress');
    const isMobile = inject('isMobileView');
    const proceedToOrder = inject('proceedToOrder');
    const requestPresentation = inject('requestPresentation');
    return {
      isMobile,
      publicPhone,
      publicAddress,
      prettifyPhone,
      proceedToOrder,
      requestPresentation,
      socials,
    };
  },
});
