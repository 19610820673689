
import {
  defineComponent, inject, reactive, Ref, ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InlineMessage from 'primevue/inlinemessage';
// import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import {
  isMinLength, isRequired, isTrue, Validation, Validator,
} from '@/validation';
// import { downloadFromLink } from '@/api';
// import { useToast } from 'primevue/usetoast';

export default defineComponent({
  name: 'PresentationOrder',
  components: {
    Dialog,
    Button,
    InputText,
    // Message,
    InlineMessage,
    InputMask,
    Checkbox,
  },
  setup() {
    const isMobile = inject('isMobileView');
    const showErrors = inject('showErrors') as CallableFunction;
    const isPresentationOrderRequested: Ref<boolean> | undefined = inject('isPresentationOrderRequested');

    const isPresentationSent: Ref<boolean> | undefined = inject('isPresentationSent');
    // const toast = useToast();

    const fieldErrors = ref({} as Partial<{ [x: string]: string }>);
    const fields = reactive({
      username: '',
      phone: '',
      isAgreementChecked: false,
    });
    const rules: Partial<{ [key: string]: Validator[] }> = {
      username: [
        isRequired('Укажите имя'),
        isMinLength(2, 'Введите верное имя'),
      ],
      phone: [isRequired('Укажите телефон')],
      // email: [isRequired('Укажите email')],
      isAgreementChecked: [
        isTrue('Поставьте галочку'),
      ],
    };

    const validate = () => {
      fieldErrors.value = new Validation(rules).validate(fields);

      return Object.keys(fieldErrors.value).length === 0;
    };

    const savePresentationOrder = inject('saveOrder') as CallableFunction;
    const downloadPresentation = inject('downloadPresentation') as CallableFunction;
    const saveOrder = () => {
      if (!validate()) {
        const firstKey = Object.keys(fieldErrors.value)[0];
        if (firstKey) {
          // eslint-disable-next-line no-unused-expressions
          document?.getElementById(firstKey)?.focus();
        }
        return;
      }

      savePresentationOrder(fields)
        .then((
          { isSaved, errors }: { isSaved: boolean, errors: string[] },
        ) => {
          if (isSaved) {
            downloadPresentation();
          } else {
            showErrors(errors);
          }
          return { isSaved, errors };
        });
    };
    const closeOrder = inject('closeOrder');
    return {
      isMobile,
      isPresentationOrderRequested,
      isPresentationSent,
      downloadPresentation,
      closeOrder,
      fields,
      fieldErrors,
      validate,
      saveOrder,
    };
  },
});
