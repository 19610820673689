
import { useRoute, useRouter } from 'vue-router';
import {
  Validation,
  // isEqual,
  isRequired,
  isNumber,
  InputStrings,
  Validator,
  isMinLength,
  isTrue,
  isBoolean,
  isEmail,
  // isTrue
} from '@/validation';
import { Step as StepInterface } from '@/interface';
// eslint-disable-next-line object-curly-newline
import { computed, defineComponent, inject, reactive, ref } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import SelectButton from 'primevue/selectbutton';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox';
import InputMask from 'primevue/inputmask';

export default defineComponent({
  name: 'Step',
  components: {
    Button,
    SelectButton,
    Checkbox,
    InputMask,
    InputText,
    InlineMessage,
    Message,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const currentStep = computed(() => route.params.step);
    const steps: StepInterface[] | undefined = inject('steps');

    const errors = ref({} as Partial<{ [x: string]: string }>);

    const binaryOptions = ref([
      { name: 'Да', value: true },
      { name: 'Нет', value: false },
    ]);

    // const bussinessExpirience = ref([
    //   { name: 'Да', value: true },
    //   { name: 'Нет', value: false },
    // ]);

    // const tourismBusiness = ref([
    //   { name: 'Да', value: true },
    //   { name: 'Нет', value: false },
    // ]);
    const fields: { [key: number]: InputStrings } = {
      0: reactive({
        // salary: '',
        hasBussinessExpirience: '',
        isTourismBusiness: '',
        ageInTourism: '',
        hasWorkingOffice: '',
        inLoveWithTourism: '',
      }),
      1: reactive({
        city: '',
        population: '',
      }),
      2: reactive({
        monthsToPayOff: '',
      }),
      3: reactive({
        username: '',
        phone: '',
        email: '',
        isAgreementChecked: false,
      }),
    };
    const rules: { [key: number]: Partial<{ [key: string]: Validator[] }> } = {
      0: {
        hasBussinessExpirience: [
          isBoolean('Укажите, есть ли у вас опыт'),
        ],
        isTourismBusiness: [
          isBoolean('Укажите, есть ли у вас опыт в туризме?'),
        ],
        ageInTourism: [
          isRequired('Укажите ваш бизнес-возраст в туризме'),
          isNumber('Введите только число, пожалуйста'),
        ],
        hasWorkingOffice: [
          isBoolean('Укажите есть ли у вас турагентство'),
        ],
      },
      1: {
        city: [
          isRequired('Укажите ваш город'),
          isMinLength(3, 'Введите верное название города'),
        ],
        population: [
          isRequired('Укажите население вашего города'),
          isNumber('Введите только число, пожалуйста'),
        ],
      },
      2: {
        monthsToPayOff: [
          isRequired('Укажите количество месяцев'),
          isNumber('Введите только число, пожалуйста'),
        ],
      },
      3: {
        username: [
          isRequired('Укажите имя'),
          isMinLength(2, 'Введите верное имя'),
        ],
        phone: [isRequired('Укажите телефон')],
        email: [isRequired('Укажите email'), isEmail('Укажите верный email, пожалуйста')],
        isAgreementChecked: [
          isTrue('Примите соглашение, для того чтобы продолжить'),
          isTrue('Примите соглашение, для того чтобы продолжить'),
        ],
      },
      // name: [isRequired('Укажите ваше ФИО полностью')],
      // phone: [isRequired('Укажите телефон')],
      // email: [isRequired('Укажите email'), isEmail('Укажите корректный email')],
      // password: [isRequired('Укажите пароль')],
      // passwordRepeat: [
      //   isRequired('Укажите подтвеждение пароля'),
      //   isEqual(() => user.password, 'Пароли не совпадают'),
      // ],
      // isAgreementChecked: [isTrue('Подтвердите согласие')],
    };

    const validate = (index: number) => {
      if (index === 0) {
        if (fields[index].hasBussinessExpirience === false) {
          errors.value = {};
          return;
        }
        if (fields[index].hasBussinessExpirience
          && fields[index].isTourismBusiness === false) {
          errors.value = {};
          return;
        }
      }

      errors.value = new Validation(rules[index]).validate(fields[index]);
    };

    const saveOrder = inject('saveStepsOrder') as CallableFunction;
    const showErrors = inject('showErrors') as CallableFunction;

    const goToStep = async (nextStep: string) => {
      if (!steps) return;

      const from = steps.find((step) => step.next === nextStep);
      if (!from) return;

      validate(from.index);

      if (Object.keys(errors.value).length === 0) {
        if (nextStep === 'success') {
          let orderIsSaved = false;
          let orderErrors: string[] = [];
          await saveOrder(fields).then((order: { [key: string]: boolean | string[] }) => {
            orderIsSaved = order.isSaved as boolean;
            orderErrors = order.errors as string[];
          });

          if (!orderIsSaved) {
            showErrors(orderErrors);
            return;
          }
        }
        router.push({ path: `/steps/${nextStep}` });
      }
    };

    const downloadPresentation = inject('downloadPresentation');
    return {
      binaryOptions,
      router,
      currentStep,
      goToStep,
      fields,
      errors,
      downloadPresentation,
    };
  },
});
