
import {
  defineComponent, inject, ref,
} from 'vue';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Menu from 'primevue/menu';
import { prettifyPhone } from '@/api';

import { MenuItem as MenuItemInterface } from '@/interface';

export default defineComponent({
  name: 'Header',
  components: {
    Toolbar,
    Button,
    SplitButton,
    Menu,
  },
  props: {
    msg: String,
  },
  setup() {
    const isHidden = inject('isScrollingToBottom');
    const isMobile = inject('isMobileView');
    const publicPhone = inject('publicPhone');
    const requestPresentation = inject('requestPresentation');
    const proceedToOrder = inject('proceedToOrder');

    const headerMenuRef = ref(null);
    const menuItems = inject('menuItems') as MenuItemInterface[];
    const toggleMenu = (e = window.event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (headerMenuRef.value) (headerMenuRef.value as any).toggle(e);
    };

    const contactItems = (inject('socials') as { [key: string]: string | boolean }[])
      .filter((s) => s.isMessenger)
      .map((s) => ({ url: s.src, label: s.title }));
    const headerLinks = menuItems.filter((item) => !!item.id && [
      // '#about',
      // '#services',
      // '#steps',
      // '#reviews',
      // '#preferences',
      // '#director',
      // '#cost',
      '#stages',
      '#bonuses',
      '#tariffs',
      '#contacts',
    ].includes(item.id));
    const moveToUrl = inject('moveToUrl');

    return {
      isHidden,
      isMobile,
      publicPhone,
      prettifyPhone,

      menuItems,
      toggleMenu,
      headerMenuRef,

      contactItems,

      headerLinks,
      moveToUrl,

      proceedToOrder,
      requestPresentation,
    };
  },
});
