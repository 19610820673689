
import {
  defineComponent, inject, reactive, Ref, ref,
} from 'vue';
import Dialog from 'primevue/dialog';
import InlineMessage from 'primevue/inlinemessage';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import Checkbox from 'primevue/checkbox';
import OrderPromo from '@/components/OrderPromo.vue';
import {
  isEmail, isMinLength, isRequired, isTrue, Validation, Validator,
} from '@/validation';

export default defineComponent({
  name: 'Order',
  components: {
    Dialog,
    Button,
    InputText,
    InputMask,
    Checkbox,
    InlineMessage,
    OrderPromo,
  },
  setup() {
    const isNewYear = inject('isNewYear');

    const isMobile = inject('isMobileView');
    const isOrderRequested: Ref<boolean> | undefined = inject('isOrderRequested');
    const closeOrder = inject('closeOrder');

    const fieldErrors = ref({} as Partial<{ [x: string]: string }>);
    const fields = reactive({
      username: '',
      phone: '',
      email: '',
      city: '',
      pin: '',
      isAgreementChecked: false,
    });
    const rules: Partial<{ [key: string]: Validator[] }> = {
      username: [
        isRequired('Укажите имя'),
        isMinLength(2, 'Введите верное имя'),
      ],
      phone: [isRequired('Укажите телефон')],
      email: [isRequired('Укажите email'), isEmail('Укажите верный email, пожалуйста')],
      city: [
        isRequired('Укажите ваш город'),
        isMinLength(3, 'Введите верное название города'),
      ],
      isAgreementChecked: [
        isTrue('Поставьте галочку'),
      ],
    };

    const validate = () => {
      fieldErrors.value = new Validation(rules).validate(fields);

      // console.log(
      //   fields,
      //   // new Validation(rules[0]).validate(fields);
      // );
      return Object.keys(fieldErrors.value).length === 0;
    };

    const saveCommonOrder = inject('saveOrder') as CallableFunction;
    const showErrors = inject('showErrors') as CallableFunction;
    const checkPhone = inject('checkPhone') as CallableFunction;

    const selectedPromo = inject('selectedPromo') as Ref;
    const isOrderSaved = ref(false);
    const isPhoneCheck = inject('isPhoneCheck') as Ref<boolean>;
    const saveOrder = () => {
      if (!validate()) {
        const firstKey = Object.keys(fieldErrors.value)[0];
        if (firstKey) {
          // eslint-disable-next-line no-unused-expressions
          document?.getElementById(firstKey)?.focus();
        }
        return;
      }

      checkPhone({ ...fields, promo: selectedPromo.value });
      isPhoneCheck.value = true;
    };

    const saveOrderWithPin = () => {
      isPhoneCheck.value = false;
      saveCommonOrder({ ...fields, promo: selectedPromo.value })
        .then((
          { isSaved, errors }: { isSaved: boolean, errors: string[] },
        ) => {
          if (isSaved) {
            isOrderSaved.value = true;
            (window as unknown as { ym: (a: number, b: string, c: string) => void }).ym(84556180, 'reachGoal', 'sendFeedback');
          } else {
            fields.pin = '';
            showErrors(errors);
          }
        });
    };

    const userNameInput = ref();
    // onActivated
    // watchEffect(() => {
    //   if (isOrderRequested?.value && userNameInput.value) {
    //     userNameInput.value.$el.focus();
    //   }
    // });
    const isAgreementChecked = ref(false);
    return {
      fields,
      fieldErrors,
      validate,
      isMobile,
      isOrderRequested,
      isPhoneCheck,
      isNewYear,
      closeOrder,
      saveOrder,
      saveOrderWithPin,
      isOrderSaved,
      isAgreementChecked,
      userNameInput,
    };
  },
});
