import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "flex align-items-center mb-3" }
const _hoisted_4 = { class: "wf-stage-index" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.stages, (stage, i) => {
      return (_openBlock(), _createBlock("div", {
        key: stage.title,
        class: "flex wf-bg-blue-50 p-4 border-round shadow-2 mb-4 wf-stage"
      }, [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("span", _hoisted_4, [
              _createVNode("img", {
                src: require(`@/assets/${i + 1}.svg`)
              }, null, 8, ["src"])
            ]),
            _createVNode("h5", {
              class: "m-0",
              innerHTML: stage.title
            }, null, 8, ["innerHTML"])
          ]),
          _createVNode("span", {
            innerHTML: stage.content
          }, null, 8, ["innerHTML"])
        ]),
        _createVNode("div", {
          class: ["flex-1 wf-stage-header", { 'wf-reversed': i % 2 !== 1 }]
        }, [
          _createVNode("img", {
            class: "wf-stage-bg",
            src: require(`@/assets/stage-${i + 1}.jpg`)
          }, null, 8, ["src"])
        ], 2)
      ]))
    }), 128))
  ]))
}