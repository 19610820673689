
/* eslint-disable */
import {
  defineComponent, inject, onBeforeUpdate, ref,
} from 'vue';
import { gsap } from 'gsap';

export default defineComponent({
  name: 'Stages',
  components: {
  },
  setup() {
    const stages = inject('stages');
    const refStages = ref<{ [key: string]: HTMLDivElement }>({});
    onBeforeUpdate(() => {
      refStages.value = {};
    });

    const openStages = ref([] as number[]);
    const toggleStage = (index: number) => {
      console.log(openStages.value);

      if (openStages.value.includes(index)) {
        openStages.value = openStages.value.filter((v) => v !== index);
        return;
      }

      openStages.value.push(index);
    };
    const isStageOpen = (index: number) => openStages.value.includes(index);

    const alreadyAnimated = ref([] as string[]);
    const animateStage = (stage: string) => {
      // console.log(refStages.value[stage]);
      gsap.fromTo(refStages.value[stage].closest('.card.component'),
        {
          y: 24,
          opacity: 0,
        }, {
        // eslint-disable-next-line
        delay: 0.3, y: 0, opacity: 1, duration: 1,
      });
    };
    const waypointChange = (id: string, e: { [key: string]: string }) => {
      // console.log(e);
      if (alreadyAnimated.value.includes(id)) return;

      if (e.going === 'IN') {
        console.log('animating', id);
        alreadyAnimated.value.push(id);
        animateStage(id);
      }
    };
    return {
      alreadyAnimated,
      waypointChange,
      stages,
      refStages,
      toggleStage,
      openStages,
      isStageOpen,
    };
  },
});
