
// import { Loader, google } from 'google-maps';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import XYZ from 'ol/source/XYZ';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Style } from 'ol/style';
import { fromLonLat } from 'ol/proj';
import { toSize } from 'ol/size';
import officeLocations from '@/lists/office_location.json';
import { defineComponent, inject, onMounted } from 'vue';

export default defineComponent({
  name: 'OfficeMap',
  components: {
  },
  setup() {
    const isMobile = inject('isMobileView');

    onMounted(() => {
      // eslint-disable-next-line no-new
      const map = new Map({
        target: 'map',
        controls: [],
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            }),
          }),
        ],
        view: new View({
          center: fromLonLat([58.30431749747, 56.136251483467]),
          maxZoom: 4,
          zoom: 4,
        }),
      });
      const offices: number[][] = officeLocations.filter(
        (loc) => loc.location !== '',
      ).map(
        // (loc) => ({
        //   lat: parseFloat(loc.location.split(',')[0]),
        //   lng: parseFloat(loc.location.split(',')[1]),
        // }),
        (loc) => loc.location.split(',').map((n) => parseInt(n, 10)).reverse(),
      );

      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 1],
          // displacement: toSize(10),
          size: toSize(50),
          scale: toSize(0.8),
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction',
          src: '/img/icons/well-marker.png',
        }),
      });

      offices.forEach((location) => {
        const feature = new Feature({
          geometry: new Point(fromLonLat(location)),
        });
        feature.setStyle(iconStyle);
        const layer = new VectorLayer({
          source: new VectorSource({
            features: [
              feature,
            ],
          }),
        });
        map.addLayer(layer);
      });
    });
    // console.log(map);
    (async () => {

      // const map = new Map({
      //   target: document.getElementById('map') as HTMLElement,
      //   layers: [
      //     new TileLayer({
      //       source: new XYZ({
      //         url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //       }),
      //     }),
      //   ],
      //   view: new View({ center, zoom }),
      // });
    })();

    return {
      // map,
      isMobile,
      // center,
      // googleApiKey,
    };
  },
});
