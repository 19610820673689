<template>
  <swiper ref="swiper" :navigation="true"
    :slides-per-view="!isMobile ? 2 : 1.3333" :space-between="16">
    <swiper-slide v-for="slide in reviews" :key="slide.author">
      <div class="card wf-bg-paper" :class="{ 'flex m-2': !isMobile }">
        <div class="p-3 wf-bg-blue-100 justify-content-center flex"
          :class="{ 'col-3 ': !isMobile }">
          <Avatar :image="require(`@/assets/${slide.avatar}`)" shape="circle"
            size="xlarge" />
        </div>

        <div class="p-3 align-center flex flex-column"
          :class="{ 'col ml-3': !isMobile }">
          <i class="
              as-start pi-angle-double-left
              pi d-block mb-2 wf-color-blue-300
            "></i>
          <p>
            {{ slide.text }}
          </p>

          <i class="
              as-end
              pi-angle-double-right pi
              d-block mt-2
              wf-color-blue-300
            "></i>
          <h5 class="m-0">{{ slide.author }}</h5>
          <h6 class="m-0">{{ slide.position }}</h6>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import {
  defineComponent, inject, ref,
} from 'vue';
import Avatar from 'primevue/avatar';
import SwiperCore, {
  Navigation,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

export default defineComponent({
  name: 'Reviews',
  components: {
    Avatar,
    Swiper,
    SwiperSlide,
  },
  setup() {
    const isMobile = inject('isMobileView');

    const reviews = [
      {
        avatar: 'partner-1.jpg',
        author: 'Овезова Ирина',
        position: 'Директор',
        text:
          'Наше сотрудничество с «ВЕЛЛ» началось с апреля 2012 года. В условиях постоянно растущей конкуренции работа под брендом «ВЕЛЛ» дает множество неоспоримых преимуществ. Поскольку на сегодняшний день сеть представлена во многих городах по всей России и за годы работы смогла завоевать безупречную репутацию надежной компании. «ВЕЛЛ» легко узнаваем, известен, что является одним из основополагающих факторов для туристов при выборе агентства.',
      },
      {
        avatar: 'partner-2.jpg',
        author: 'Евгений Басюк',
        position: 'Директор',
        text:
          'Открывая турагентство, всегда возникает множество вопросов. Что означает работать в туристическом бизнесе? Что означает работать с ВЕЛЛ? Это постоянное обучение, всесторонняя поддержка и юридическая помощь, удобство организации рабочего процесса. С ВЕЛЛ мы работаем уже больше года, день изо дня нас поддерживает Центральный офис ВЕЛЛ, помогая нам в развитии и в решении задач. Мы выражаем искреннюю признательность сотрудникам Центрального офиса за поддержку и профессионализм! Новые горизонты? Легко!',
      },
      {
        avatar: 'partner-3.jpg',
        author: 'Горошко Жанна',
        position: 'Директор',
        text:
          'Офис Велл-Пинск работает с франшизой Велл небольшой период, набираем опыт. Офис Велл и ЦБ — отличные партнеры, на которых мы можем положится во всех ситуациях. Начинали работу с минимальными знаниями и капиталом, не ожидали, что за такой срок мы будем уверенно работать в сфере туризма и не представляли, как все получится. Сейчас мы очень позитивно и динамичног развиваемся.',
      },
      {
        avatar: 'partner-4.jpg',
        author: 'Лиляк Роман',
        position: 'Директор',
        text:
          'Компания ВЕЛЛ, похожа на большую дружную семью. И я с первого дня работы, очень сильно ощутил родительскую заботу о моей компании. В первое время, мне было очень трудно и многое не понятно. У меня было очень много вопросов. Но я точно знал, что сотрудники нашей компании вседа мне во всем помогут и ответят на любой мой вопрос. Вот уже целый года работает наше агенство. Много это, или мало? Не знаю, наверное и много и мало)) Нас знают, надеюсь ценят, и уверен вспоминают про нашу компанию.',
      },
    ];
    const swiper = ref(null);
    // onMounted(() => console.log(swiper));
    return {
      isMobile,
      reviews,
      swiper,
    };
  },
});
</script>
