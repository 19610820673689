
/* eslint-disable max-len */
import {
  defineComponent, inject, Ref,
} from 'vue';
// import RadioButton from 'primevue/radiobutton';
import Chip from 'primevue/chip';
// import Card from 'primevue/card';

export default defineComponent({
  components: {
    // Card,
    // RadioButton,
    Chip,
  },
  setup() {
    const presents = inject('presents');
    const selectedPromo = inject('selectedPromo') as Ref;
    const selectPromo = (val) => {
      selectedPromo.value = val;
    };
    const getSeason = inject('getSeason') as (number) => number;
    const seasons = inject('seasons') as string[];

    let season = seasons[getSeason((new Date()).getMonth())];
    season = `${season.substring(0, season.length - 2)}яя`;
    return {
      presents,
      selectedPromo,
      selectPromo,
      season,
    };
  },
});
