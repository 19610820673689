import { Step as StepInterface } from '@/interface';
import dayjs from 'dayjs';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import ToastService from 'primevue/toastservice';
import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import { isMobile } from './api';
import App from './App.vue';
import Step from './components/Step.vue';
// import './registerServiceWorker';

const app = createApp(App);
const publicPhone = '79258601345';
const publicEmail = 'mail@well.ru';
const publicAddress = '115225, Россия, Москва, Дубининская улица, д.80, Бизнес-Центр, эт.5';
app.provide('publicPhone', publicPhone);
app.provide('publicEmail', publicEmail);
app.provide('publicAddress', publicAddress);
app.provide('googleApiKey', 'AIzaSyA0SHHu_NwmHtXXvkSEB3OXMhWvzSeyRsg');
app.provide('gateway', 'https://well.ru/ajax/wf.php');
// app.provide('presentationLink', 'https://franchisa.well.ru/well-franchise.zip');
app.provide('presentationLink', 'https://велл.рф/media/well-franchise.xlsx');

const seasons: string[] = [
  'Зимние',
  'Весенние',
  'Летние',
  'Осенние',
];
app.provide('seasons', seasons);
function getSeason(monthNum): number {
  let season;
  switch (true) {
    case monthNum < 2 || monthNum === 11: {
      season = 0; break;
    }
    case monthNum < 5: {
      season = 1; break;
    }
    case monthNum < 8: {
      season = 2; break;
    }
    case monthNum < 11: default: {
      season = 3; break;
    }
  }
  return season;
}
app.provide('getSeason', getSeason);
const months: string[] = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
app.provide('months', months);

const steps: StepInterface[] = [
  {
    index: 0,
    label: 'Опыт',
    to: '/steps',
    next: 'city',
  },
  {
    index: 1,
    label: 'Город',
    to: '/steps/city',
    next: 'cost',
  },
  {
    index: 2,
    label: 'Расходы',
    to: '/steps/cost',
    next: 'contacts',
  },
  {
    index: 3,
    label: 'Контакты',
    to: '/steps/contacts',
    next: 'success',
  },
  {
    index: 4,
    label: 'Успех!',
    to: '/steps/success',
  },
];
app.provide('steps', steps);

const services = [
  '20 лет на рынке',
  'Помощь и поддержка на всех этапах',
  'Удобная система для работы «ТурАдмин»',
  'Собственный центр бронирования',
  'Бесплатное обучение на протяжении всего сотрудничества с нами',
  'Минимальные затраты на открытие вашего бизнеса',
  'Повышенная комиссия по туроператорам, с первого рабочего дня',
  'Никаких планов продаж',
];
app.provide('services', services);

const preferences = [
  ['Крайне малые затраты на старте', 'По сравнению с другими сферами затраты на аренду, оборудование и персонал минимальны'],
  ['Проще отбить вложения', 'Вложенные средства быстро окупаются и отсутствует лицензирование'],
  ['Легко управлять персоналом', 'Не требует огромного найма сотрудников'],
  ['Доступные знания', 'Легко обучиться и при этом не обязательно иметь опыт'],
  ['Отсутствие логистики', 'Вам не нужны склад и инвестиции в товарный запас'],
  ['Жажда путешествий', 'Всегда большой спрос на тур услуги среди клиентов любых возрастов'],
];
app.provide('preferences', preferences);

const stages = [
  {
    title: 'Подписание договора',
    time: 'Происходит моментально',
    icon: 'pi-file-o',
    content: 'После выбора определённого тарифа, мы подписываем с вами договор и даем срок на подготовку вашего бизнеса 6 месяцев. Договор может быть составлен как на Физ. Лицо так и на Юр. Лицо. Это значительно ускоряет возможность  включиться в работу и занять место в вашем городе, а места  у нас ограниченны. ',
  },
  {
    title: 'Доступ в личный кабинет<br> нашей системы ТурАдмин',
    time: '2 день',
    icon: 'pi-unlock',
    content: 'Вы получаете доступ в нашу уникальную систему ТурАдмин. Здесь собраны все необходимые инструменты для работы менеджера и руководителя. Обращение клиентов, Бронирование, удобный подбор тура для клиента и многое другое.',
  },
  {
    title: 'Расширенный курс обучения директора<br> и его сотрудников',
    time: '1 неделя',
    icon: 'pi-users',
    content: 'Наша команда профессионалов подготовила для вас курс обучения, где каждый абсолютно легко сможет усвоить программу курса и спокойно разбираться на кухне туристического рынка. ',
  },
  // {
  //   title: 'Оформление юридических документов<br> ИП или ООО',
  //   time: '2 неделя',
  //   icon: 'pi-folder-open',
  //   content: 'Для регистрации ООО понадобится внушительный пакет, а для ИП — только заявление',
  // },
  {
    title: 'Выбор офиса <br> и его подготовка к работе',
    time: '1 месяц',
    icon: 'pi-home',
    content: 'Подсказываем Вам какое лучше выбрать место чтобы оно приносило вам доход, что необходимо для обустройства вашего офиса. Наш дизайнер готов подготовить вам макеты чтобы украсить ваш офис нашим ярким и красочным логотипом «ВЕЛЛ».',
  },
  {
    title: 'Запуск вашего собственного сайта с поисковой системой и дополнительными модулями',
    time: '1 месяц',
    icon: 'pi-desktop',
    content: 'Мы создаем для вас сайт с поисковым  модулем туров для вашего туриста, а так же наполняем его дополнительными модулями специальных предложения, для большего вовлечения. Ваш сайт будет сгенерирован с вашим личным кабинетом, что дает вам возможность получать заявку прямо в ТурАдмин. <br> Настраиваем таргетированную рекламу для Яндекс Директ, оформляем группу Вконтакте.',
  },
];
app.provide('stages', stages);

const bonuses = [
  'Надежное плечо и поддержку с первого дня',
  'Бесплатные макеты',
  'Бесплатный сайт с поисковой системой',
  'Бесплатное обучение',
  'Система ТурАдмин',
  'Встроенная CRM "Обращения клиентов"',
  'Центр бронирования с повышенной комиссией',
];
app.provide('bonuses', bonuses);

const socials = [
  {
    isMessenger: true,
    src: `https://api.whatsapp.com/send?phone=${publicPhone}`,
    title: 'WhatsApp',
    color: '#25D366',
    icon: 'whatsapp.svg',
  },
  {
    isMessenger: true,
    src: isMobile()
      ? `viber://chat?number=%2B${publicPhone}`
      : `viber://add?number=%2B${publicPhone}`,
    title: 'Viber',
    color: '#7360F2',
    icon: 'viber.svg',
  },
  {
    isMessenger: true,
    src: 'https://t.me/centrwell',
    title: 'Telegram',
    color: '#26A5E4',
    icon: 'telegram.svg',
  },
  // {
  //   src: 'https://www.facebook.com/well.ru.75',
  //   title: 'Facebook',
  //   color: '#1877F2',
  //   icon: 'facebook.svg',
  // },
  {
    src: 'https://vk.com/club36511729',
    title: 'Vk',
    color: '#4680C2',
    icon: 'vk.svg',
  },
  // {
  //   src: 'https://instagram.com/well_ru_official/',
  //   title: 'Instagram',
  //   color: '#E4405F',
  //   icon: 'instagram.svg',
  // },
];
app.provide('socials', socials);

// const commonIncludes = () => [
//   'Юридическая поддержка',
//   'Поддержка по Туроператорам',
//   'Бесплатные макеты от Дизайнера',
//   'Повышенная комиссия<br> от всех ведущих Туроператоров',
//   'Доступ  к системе ТурАдмин',
//   'Свой личный сайт',
//   'Модуль поисковика',
//   'Рекламные туры',
//   'Бесплатное обучение<br> (сертификат по окончанию обучения)',
// ];
const tariffs = [
  {
    id: 'freelancer',
    title: 'Старт',
    description: '',
    // includes: [
    //   'Центр бронирования',
    //   'Доступ в систему ТурАдмин',
    //   'Повышенную комиссию от Туроператора',
    //   'Бесплатное обучение<br> (сертификат по окончанию обучения)',
    // ],
    includes: [
      'Система поиска туров по всем туроператорам',
      'Система поиска и бронирования отелей и авиабилетов',
      'Бронирование трансферов в 600 аэропортах по всему миру',
      'Оформление договоров с туристом и других необходимых'
        + 'документов через систему Велл ТурАдмин',
      'Оформление туристических страховок',
      'Повышенная комиссия Туроператоров',
      'Единый Центр Бронирования',
      'Бесплатное обучение бронированию в системе ТА',
    ],
    lacks: [
      'Юридическая поддержка',
      'Поддержка по Туроператорам',
      'Бесплатные макеты от Дизайнера',
      'Свой личный сайт',
      'Модуль поисковика',
      'Рекламные туры',
    ],
    prices: [
      { title: 'Модуль Поисковик туров в группе ВК', price: 1_000 },
      { title: 'Модуль Поисковик туров на сайте', price: 1_000 },
      { title: 'Модуль Поисковик отелей', price: 500 },
      { title: 'Модуль Поисковик авабилетов', price: 500 },
      { title: 'Модуль Витрина туров', price: 500 },
      { title: 'Оформление группы ВК', price: 1_000 },
      { title: 'Разработка вашего собственного модуля', price: 10_000 },
      { title: 'Таргетированная реклама соц.сетей и сайта', price: 10_000 },
      { title: 'Обучение Менеджера по туризму', price: 25_000 },
      // { title: 'Программа для постов спец. Предложений  для Инстаграм', price: 500 },
      { title: 'Модуль квиз для вашего сайта и соц. Сетей', price: 600 },
    ],
  },
  {
    id: 'standart',
    title: 'Стандарт',
    description: '',
    includes: [
      'Право работать под вывеской Велл',
      'Бесплатное обучение: Страноведение (Россия, Турция, Египет)',

      //  former start
      'Система поиска туров по всем туроператорам',
      'Система поиска и бронирования отелей и авиабилетов',
      'Бронирование трансферов в 600 аэропортах по всему миру',
      'Оформление договоров с туристом и других необходимых'
        + 'документов через систему Велл ТурАдмин',
      'Оформление туристических страховок',
      'Повышенная комиссия Туроператоров',
      'Единый Центр Бронирования',
      'Бесплатное обучение бронированию в системе ТА',

      //  std
      'Готовый интернет сайт с поисковой системой, модулем Отборные Туры, модулем Спецпредложения',
      'Юридическая поддержка',
      'Поддержка по Туроператорам (помощь в решении вопросов по аннуляциям и возвратам денежных средств)',
      'CRM-система ( регистрация обращений туристов и  подготовка ответов на запросы клиентов)',
    ],
    prices: [
    ],
    bonuses: [
      // 'Все сервисы плана "Старт"',
    ],
  },
  {
    id: 'standart+',
    title: 'Стандарт Плюс',
    description: '',
    includes: [
      'Бесплатный инфотур в страну по Вашему выбору (Турция, Египет, Россия)',
      'Дополнительные модули обучения: Навыки Продаж, Страноведение Европа, Страноведение Карибы',
      'Дополнительные модули на сайт: Отзывы туристов, Слайдер (туры по вашему выборы)',
      'Бонусная программа',
      'Бесплатная настройка таргетированной рекламы в Яндекс Директ',
      'Оформление Группы ВК',
      'Личный Куратор',
    ],
    prices: [
    ],
    bonuses: [
      'Все сервисы плана "Стандарт"',
    ],
  },
  {
    id: 'premium',
    title: 'Премиум',
    description: '',
    includes: [
      'Рекламная кампания для Вашего агентства в Яндекс и ВК',
      'Набор сувенирной продукции для оформления агентства',
      'Помощь в поиске турменеджеров',
      // 'Приоритетная поддержка',
      'Бесплатные макеты рекламных материалов',
    ],
    prices: [
    ],
    bonuses: [
      'Все сервисы плана "Стандарт Плюс"',
    ],
  },
];
app.provide('tariffs', tariffs);

const tomorrow = dayjs().add(1, 'day').startOf('day');
const useSchedules = (schedules) => schedules.map((schedule, id) => {
  if (schedule.raw === 'busy') {
    return {
      ...schedule,
      id,
      title: 'Занято',
      category: 'time',
      borderColor: 'var(--blue-200)',
      color: 'var(--blue-200)',
      bgColor: 'var(--blue-50)',
    };
  }
  return {
    ...schedule,
    id,
    title: 'Свободно',
    category: 'time',
    borderColor: 'var(--blue-500)',
    bgColor: 'var(--blue-200)',
  };
});

const schedules = useSchedules([
  {
    raw: 'busy',
    start: tomorrow.hour(10).format(),
    end: tomorrow.hour(11).format(),
  },
  {
    raw: 'busy',
    start: tomorrow.hour(11).format(),
    end: tomorrow.hour(12).format(),
  },
  {
    raw: 'busy',
    start: tomorrow.hour(12).format(),
    end: tomorrow.hour(13).format(),
  },
  {
    raw: 'free',
    start: tomorrow.hour(14).format(),
    end: tomorrow.hour(15).format(),
  },
]);
app.provide('schedule', schedules);

const presents = [
  'Регистрация ИП/ООО в ФНС. Консультации юриста по  ОКВЭД и налогам',
  // 'Бухучет на 6 месяцев',
  // 'Настройка бизнес-аккаунта в ВК и Instagram',
  'Живое трехдневное индивидуальное обучение. Сертификат',
  'Сувениры: часы, ручки, конверты, раб. тетрадь, чашка, пакеты, календарь',
];
app.provide('presents', presents);

const routes = [
  { path: '/', component: App },
  { path: '/steps/', component: Step },
  { path: '/steps/:step', component: Step },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
app.use(router);

app.use(ToastService);
app.use(PrimeVue, { ripple: true });

app.mount('#app');

/**
* @todo
* decreese bundle-images sizes
*/
